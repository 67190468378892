import React from 'react';
import { graphql } from 'gatsby';

// Layout Imports
import Default from '../layouts/Default/Default';
import SEO from '../layouts/SEO/SEO';

const NotFoundPage = (props) => {
    const { data } = props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Default
        location={props.location}
        title={siteTitle}
        heading='Page not found'
      >
        <SEO title='404: Not Found' />

        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Default>
    );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;